body {
	& > ul,
	& > div,
	footer {
		display: none;
	}

	.offCanvasWrap,
	.leftOffCanvas,
	.rightOffCanvas {
		// sass-lint:disable-all
		display: none !important;
		// sass-lint:enable-all
	}

	.page {
		width: 1200px;
		display: block;

		& > ul {
			display: none;
		}
	}

	.header--wrap {
		div,
		ul {
			display: none;
		}

		.header--logo {
			display: block;
			width: 300px;
			height: auto;
		}
	}

	.mainMenu {
		display: none;
	}

	.mainContent {
		padding: 0 20px;
	}
}
